<template>
  <b-card>

    <div
      v-if="isLoading"
    >
      <custom-loading></custom-loading>
    </div>
    <div
      v-else
    >
      <!-- PERMISSION TABLE -->
      <b-card
        no-body
        class="border mt-1"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="LockIcon"
              size="18"
            />
            <span class="align-middle ml-50">Bloquear Acceso</span>
          </b-card-title>
        </b-card-header>
        <b-table
          striped
          responsive
          class="mb-0"
          :fields="tableColumns"
          :items="permissionsData"
        >
          <template #cell(subject)="data">
            {{resolvePermissionName(data.value)}}
          </template>
          <template #cell(isBlocked)="data">
            <b-form-checkbox 
              v-model="data.item.isBlocked"
              :value="1"
              :unchecked-value="0"
            />
          </template>
        </b-table>
      </b-card>

      <!-- Action Buttons -->
      <b-button
        v-if="isLoadingBtn"
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        disabled
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        <b-spinner small />
        <span> Loading...</span>
      </b-button>
      <b-button
        v-else
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="saveData"
      >
        Save Changes
      </b-button>
      <!-- <b-button
        variant="outline-secondary"
        type="reset"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        Reset
      </b-button> -->
    </div>

  </b-card>
</template>

<script>
import {
  BCard,
  BButton, BRow, BCol, BTable, BCardHeader, BCardTitle, BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'
import { ref, getCurrentInstance } from '@vue/composition-api'
import vSelect from 'vue-select'
import axios from '@axios'
import CustomLoading from '@/modules/common/CustomLoading.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BTable,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BSpinner,

    vSelect,
    CustomLoading,
  },
  setup() {
    const permissionsData = ref([])
    const isLoading = ref(true)
    const isLoadingBtn = ref(false)

    const vm = getCurrentInstance().proxy

    const tableColumns = [
      { key: 'subject', label: 'Proceso'},
      { key: 'isBlocked', label: 'Bloquear'},
    ]

    const resolvePermissionName = permission => {
      if (permission === 'CargarDatos') return 'Cargar Datos'
      return permission
    }

    const saveData = () => {
      isLoadingBtn.value = true

      axios
      .post(`${process.env.VUE_APP_BASE_URL}/update_permissions`, 
        { 
          data: permissionsData.value
        }
      )
      .then(response => { 
        // console.log(response)
        vm.$swal({
          title: 'Permisos actualizados!',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
          willClose: fetchPermissionData
        }) 
      })
      .catch(error => {
        console.log(error)
        vm.$swal({
          title: 'Erro al editar usuario!',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
      .finally(() => {
        isLoadingBtn.value = false
      })
    }

    const fetchPermissionData = () => {
      isLoading.value = true

      axios
      .post(`${process.env.VUE_APP_BASE_URL}/get_permission`, 
        { action: 'block' }
      )
      .then(response => { 
        permissionsData.value = response.data.result
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        isLoading.value = false
      })
    }

    fetchPermissionData()

    return {
      isLoading,
      isLoadingBtn,
      tableColumns,
      permissionsData,

      saveData,
      resolvePermissionName,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
