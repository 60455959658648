<template>
  <b-row class="match-height">
    <b-col
      md="6"
      lg="4"
    >
      <b-card class="b-card-process">
        <b-card-title>
          Generar Data SOP
        </b-card-title>

        <b-card-text>
          Procesa los datos de los inputs para generar los visuales en SOP Simulation
        </b-card-text>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          @click="generarDataSop"
        >
          Ejecutar
        </b-button>
      </b-card>
    </b-col>
    <b-col
      md="6"
      lg="4"
    >
      <b-card class="b-card-process">
        <b-card-title>
          Cerrar Proceso SOP
        </b-card-title>

        <b-card-text>
          Guarda los cambios realizados en el SOP Simulation del mes en curso
        </b-card-text>
  
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          @click="cerrarDataSop"
        >
          Ejecutar
        </b-button>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardText, BButton, BRow, BCol, BImg, BCardBody, BCardTitle, BLink,
  BMedia,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, getCurrentInstance } from '@vue/composition-api'
import axios from '@axios'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    BMedia,
  },
  directives: {
    Ripple,
  },
  setup() {

    const vm = getCurrentInstance().proxy

    const generarDataSop = () => {
      vm.$swal({
        title: `¿Está seguro de ejecutar el proceso Generar Data SOP?`,
        text: 'Si hay datos en el mes en curso, estos se borrarán y se volverá a ingresar otra vez los datos',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return axios
            .post(`${process.env.VUE_APP_BASE_URL}/cargar_db_main`)
            .then(response => {
              if (response.status != 200) {
                throw new Error(response.statusText)
              }
              return response
            })
            .catch(error => {
              vm.$swal.showValidationMessage(`Request failed:  ${error}`)
            })
        },
      }).then( async (result) => {
        if (result.value) {
          vm.$swal({
            icon: 'success',
            title: 'Proceso Ejecutado!',
            customClass: {
              confirmButton: 'btn btn-success',
            }
          })
        } 
      })
    }

    const cerrarDataSop = () => {
      vm.$swal({
        title: `¿Está seguro de ejecutar el proceso Cerrar Proceso Sop?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return axios
            .post(`${process.env.VUE_APP_BASE_URL}/cerrar_db_main`)
            .then(response => {
              if (response.status != 200) {
                throw new Error(response.statusText)
              }
              return response
            })
            .catch(error => {
              vm.$swal.showValidationMessage(`Request failed:  ${error}`)
            })
        },
      }).then( async (result) => {
        if (result.value) {
          vm.$swal({
            icon: 'success',
            title: 'Proceso Ejecutado!',
            customClass: {
              confirmButton: 'btn btn-success',
            }
          })
        } 
      })
    }

    return {
      generarDataSop,
      cerrarDataSop,
    }
  }
}
</script>

<style lang="scss">
.b-card-process > .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
